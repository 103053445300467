import React from "react";
import  {PagePoste } from 'amg-it-theme'; 

let pageData = {
	header:  {
		background: "metiers",
		title: "support",
		path: "accueil / nos métiers / support / Technicien Support Applicatif"
	},
	title: "Technicien Support Applicatif",
	missions:[
	 {
	   text: "Intervenir à distance (par téléphone, mail…) et/ou au poste de travail de l’utilisateur" 
	 },
	 {
	   text: "Assurer l’assistance des utilisateurs dans un ou plusieurs domaines techniques en apportant des réponses précises" 
	 },
	 {
	   text: "Effectuer, le cas échéant, une escalade vers le centre de compétence adapté." 
	 },

	],

	key_skills: [
	 {
	   text: "Expression écrite" 
	 },
	 {
	   text: "Gérer la relation utilisateur" 
	 },
	 {
	   text: "Agréabilité" 
	 },
	 {
	   text: "Information" 
	 },
	 {
	   text: "Qualification" 
	 },
	 {
	   text: "Empathie" 
	 },
	 {
	   text: "Organiser" 
	 },
	 {
	   text: "Être consciencieux" 
	 },
	 {
	   text: "Analyser et comprendre" 
	 },
	 {
	   text: "Collaborer" 
	 },
	],
	prerequisites:[
	 {
	   text: "Expression orale adaptée à un contexte professionnel" 
	 },
	 {
	   text: "Bac + une formation qualifiante en informatique OU une expérience d’au moins deux ans dans le métier" 
	 },
	 {
	   text: "Connaissances fonctionnelles" 
	 },
	],
	activities:[
	 {
	   text: "Accueillir les demandes" 
	 },
	 {
	   text: "Qualifier la demande suivant la nomenclature" 
	 },
	 {
	   text: "Traiter la demande suivant les procédures" 
	 },
	 {
	   text: "Réaliser un diagnostic selon un mode opératoire établi en s’appuyant sur les outils à sa disposition (arbres de logique, scenarii, etc.) et en mettant en œuvre ses connaissances techniques et fonctionnelles" 
	 },
	 {
	   text: "Saisir les éléments du dossier et réaliser un descriptif précis de la demande au fur et à mesure de sa conversation avec l’utilisateur" 
	 },
	 {
	   text: "Traiter le dossier à l’aide des outils mis à sa disposition (base de connaissances, prise en main à distance, etc.) en suivant les instructions (procédures, modes opératoires, consignes, etc.) et en utilisant ses connaissances fonctionnelles" 
	 },
	 {
	   text: "Solliciter si nécessaire l’aide des personnes compétentes" 
	 },
	 {
	   text: "Transférer si nécessaire aux interlocuteurs compétents" 
	 },
	 {
	   text: "Apporter des réponses adaptées à la demande et aux besoins de l'utilisateur" 
	 },
	 {
	   text: "Déterminer le niveau en informatique et le niveau d’autonomie de l’utilisateur et s’adapte en conséquence" 
	 },
	 {
	   text: "Conseiller, assister et informer l’utilisateur en fonction de la demande de celui-ci et les limites de son périmètre d’intervention" 
	 },
	 {
	   text: "S’assurer que la demande de l’utilisateur est satisfaite" 
	 },
	 {
	   text: "Assurer le suivi des dossiers" 
	 },
	 {
	   text: "Tenir informé l’utilisateur du suivi de son dossier" 
	 },
	 {
	   text: "Etre attentif au respect des délais contractuels et des RDV" 
	 },
	 {
	   text: "Relancer si nécessaire les services qui doivent intervenir" 
	 },
	 {
	   text: "Collaborer avec les experts des applications" 
	 },
	 {
	   text: "Réaliser des tests sur les applications liés à des remontées des utilisateurs" 
	 },
	 {
	   text: "Participer à des formations avec les utilisateurs, les Key Users, les équipes Etudes/Applications et rédiger des modes opératoires" 
	 },
	 {
	   text: "Partager ses connaissances avec le reste de l’équipe" 
    }
  ]
}

const LocalPage = () => (
  <PagePoste  {...pageData }/>
);

export default LocalPage;
